<template>
  <div>
    <el-tooltip
        effect="dark"
        content="打开数据大展"
        placement="bottom">
      <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
        <d2-icon name="globe" style="font-size: 18px;"/>
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  data () {
    return {
      openDataUrl: 'https://b.datav.run/share/page/bb5b85851bdf0a4e4f18bb0c614c4b15'
    }
  },
  methods: {
    handleClick () {
      window.open(this.openDataUrl, '_blank')
    }
  }
}
</script>

<style scoped>

</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-data-v/index.vue"
}
</vue-filename-injector>
